export const profile = {
  BASE_URL: 'profile'
}

export const hiring = {
  BASE_URL: 'hire-me'
}


export const contact = {
  BASE_URL: 'send-contact'
}


export const testimonial = {
  BASE_URL: 'send-testimonial'
}
